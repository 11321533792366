import logo from "./logo.svg";
import "./App.css";
import Plausible from "plausible-tracker";

const { trackPageview } = Plausible({
  domain: "peachtree-partners.com",
});
trackPageview();

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p id="banner-text" className="handwritten">Coming soon...</p>
      </header>
    </div>
  );
}

export default App;
